import { Component, Input, TemplateRef } from "@angular/core";

@Component({
  selector: "c-table",
  templateUrl: "./c-table.component.html",
  styleUrls: ["./c-table.component.scss"]
})
export class TableComponent {
  @Input() items?: unknown[];
  @Input() stripes?: boolean;
  @Input() headTmp?: TemplateRef<unknown>;
  @Input() bodyTmp?: TemplateRef<unknown>;
}
