import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslatePipe } from "./pipe/translation.filter.pipe";
import { InputComponent } from "./components/c-input/c-input.component";
import { ButtonComponent } from "./components/c-button/c-button.component";
import { HeaderComponent } from "./components/c-header/c-header.component";
import { DrawerComponent } from "./components/c-drawer/c-drawer.component";
import { TableComponent } from "./components/c-table/c-table.component";
import { TableDataComponent } from "./components/c-table-data/c-table-data.component";
import { TableHeadComponent } from "./components/c-table-head/c-table-head.component";
import { TableOptionsDirective } from "./directives/table-options.directive";
import { PaginationComponent } from "./components/c-pagination/c-pagination.component";
import { UsersPipe } from "./pipe/users.pipe";
import { BaseModalComponent } from "./components/c-base-modal/c-base-modal.component";
import { ModalComponent } from "./components/c-modal/c-modal.component";
import { ToastComponent } from "./components/c-toast/c-toast.component";
import { RemoveAfterDirective } from "./directives/remove-after.directive";
import { LoaderComponent } from "./components/c-loader/c-loader.component";


@NgModule({
  declarations: [
    // Pipes
    TranslatePipe,

    // Components
    InputComponent,
    ButtonComponent,
    HeaderComponent,
    DrawerComponent,
    TableComponent,
    TableDataComponent,
    TableHeadComponent,
    PaginationComponent,
    UsersPipe,
    BaseModalComponent,
    ModalComponent,
    ToastComponent,
    LoaderComponent
  ],
  imports: [
    // Modules
    CommonModule,
    FormsModule,
    NgSelectModule,
    RouterModule,
    ReactiveFormsModule,
    NgbDatepickerModule,

    // Directives
    TableOptionsDirective,
    RemoveAfterDirective
  ],
  exports: [
    // Directives
    TableOptionsDirective,

    // Pipes
    TranslatePipe,
    UsersPipe,

    // Components
    InputComponent,
    ButtonComponent,
    HeaderComponent,
    DrawerComponent,
    TableComponent,
    TableDataComponent,
    TableHeadComponent,
    PaginationComponent,
    BaseModalComponent,
    ModalComponent,
    ToastComponent,
    LoaderComponent
  ],
  providers: []
})
export class SharedModule { }
