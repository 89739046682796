<base-modal [id]="id" [mandatory]="mandatory">
  <div class="modal">

    <div class="modal__close" (click)="onClose.emit()">
      <img src="./assets/icons/close.svg">
    </div>

    <div class="modal__header">
      <div class="modal__header-title">
        <p>{{ title }}</p>
      </div>
    </div>

    <ng-content></ng-content>

    <div class="modal__actions">
      <div class="modal__actions-cancel" *ngIf="cancelButton">
        <c-button
          btnSize="sm"
          btnStyle="ghost"
          [btnItem]="cancelButton"
          (onClick)="this.onCancel.emit()">
        </c-button>

      </div>
      <div class="modal__actions-confirm" *ngIf="confirmButton">
        <c-button
          btnSize="sm"
          btnStyle="red"
          [disabled]="confirmDisabled"
          [btnItem]="confirmButton"
          (onClick)="this.onConfirm.emit()">
        </c-button>
      </div>
    </div>
  </div>
</base-modal>