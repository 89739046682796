<c-header
    *ngIf="appService.showHeader"
    [items]="drawerItems"
    (onOpen)="loadMenuOptions()"
    (onRedirection)="headerOption($event)">
</c-header>

<div class="page-container">
    <router-outlet (activate)="appService.setHeaderVisibility()"></router-outlet>
</div>

<c-toast [hideAfter]="1000"></c-toast>