import { Component } from "@angular/core";
import { VisibilityCone, VisibilityConeImportResult } from "generali-visibilities-tool-cl";
import { BaseComponent } from "src/ancestors/base/base.component";

@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.scss"]
})
export class HomepageComponent extends BaseComponent {
  constructor() {
    super();
    this.logger.setCaller("HomepageComponent");
  }
  protected readonly pageSizes: number[] = [10, 50, 100];
  protected pageSize = this.pageSizes[0];
  protected visibilityConeList: VisibilityCone[] = [];
  protected visibilityConeCount = 0;
  protected currentPage = 1;
  protected fileImportRes?: VisibilityConeImportResult;
  protected uploadLoader = false;

  override async ngOnInit(): Promise<void> {
    await this.syncPageData();
  }
  
  protected async syncPageData() {
    const [visibilityConeList, visibilityConeCount] = await Promise.all([
      this.backendService.visibilityConeList(this.fromRecord, this.pageSize),
      this.backendService.visibilityConeCount()
    ]);

    this.visibilityConeList = visibilityConeList;
    this.visibilityConeCount = visibilityConeCount;
  }

  protected async uploadExcell(e: Event) {
    this.uploadLoader = true;
    const file = (e.target as HTMLInputElement).files?.item(0);
    const formData = new FormData();
    
    if (!file) return;
    
    formData.append("file", file);
    
    this.fileImportRes = await this.backendService.visibilityConeImport(formData, false);
    
    if (this.fileImportRes) {
      // this.toastService.success(
        //   this.translateService.instant("homepage.UPLOAD_SUCCESS")
        // );
        
        this.modalService.open("importReport", { dimension: "lg" });
        
        await this.syncPageData();
      }
      
      this.uploadLoader = false;
  }

  protected get fromRecord() {
    return (this.currentPage - 1) * this.pageSize;
  }

  protected async onPageChange() {
    await this.syncPageData();
  }

  protected async onSizeChange(target: Event) {
    const { value: size } = target.target as HTMLInputElement;
    this.currentPage = 1;
    this.pageSize = parseInt(size, 10);

    await this.syncPageData();
  }

  protected onReportModalClose() {
    this.fileImportRes = undefined;
    this.modalService.close("importReport");
  }

  protected async downloadVisibilityConeList() {
    const singleUseToken = await this.backendService.createSingleUseToken();
    const fileName = await this.backendService.currentVisibilityConesReportName();

    if (singleUseToken && fileName) {
      this.backendService.fileDownload(fileName, singleUseToken);
    }
  }
}
