import { Component } from "@angular/core";
import { TableOptionsDirective } from "../../directives/table-options.directive";

@Component({
  selector: "c-table-data",
  template: "<ng-content></ng-content>",
  styles: [`
    :host {
      display: table-cell;
      vertical-align: start;
      font-size: 12px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      padding: 15px 12px 15px 12px;
      border-bottom: 1px solid #E8E8E8;
      text-overflow: ellipsis;
      overflow: hidden;
    }`
  ],
  hostDirectives: [
    {
      directive: TableOptionsDirective,
      inputs: ["align", "dropdown"]
    }
  ]
})
export class TableDataComponent { }
