import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class ToastService {
  toasts: { text: string, status: string }[] = [];

  show(text: string) {
    this.toasts.push({ text, status: "NORMAL" });
  }

  success(text: string) {
    this.toasts.push({ text, status: "SUCCESS" });
  }

  error(text: string) {
    this.toasts.push({ text, status: "ERROR" });
  }

  clear(index?: number) {
    if (index !== undefined && index >= 0) {
      this.toasts.splice(index, 1);
    } else {
      this.toasts.splice(0, this.toasts.length);
    }
  }
}