import { Pipe, PipeTransform } from "@angular/core";
import { User } from "generali-visibilities-tool-cl";

@Pipe({
  name: "usersSpreader"
})
export class UsersPipe implements PipeTransform {

  transform(value?: User[]) {
    return value?.map(user => `${user.forename} ${user.surname}`).join(", ");
  }

}
