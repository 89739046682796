import { Component, ErrorHandler, OnDestroy, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApplicationDataService } from "src/app/global-service/application-data.service";
import { BackendService } from "src/app/global-service/backend-api.service";
import { TokenManagerService } from "src/app/global-service/token-manager.service";
import { TokenStorageService } from "src/app/global-service/token-storage.service";
import { EnvironmentLoaderService, GeneralConfig } from "../env-config.service";
import { LoggerService } from "../logger.service";
import { UserService } from "src/app/global-service/user.service";
import { TranslateService } from "src/app/shared/util/translate.service";
import { ToastService } from "src/app/shared/components/c-toast/c-toast.service";
import { BaseModalService } from "src/app/shared/components/c-base-modal/c-base-modal.service";


@Component({
  selector: "app-base",
  templateUrl: "./base.component.html",
  styleUrls: ["./base.component.scss"]
})
export class BaseComponent implements OnInit, OnDestroy {
  protected envService: EnvironmentLoaderService = inject(EnvironmentLoaderService);
  protected appService: ApplicationDataService = inject(ApplicationDataService);
  protected tkManager: TokenManagerService = inject(TokenManagerService);
  protected tStorage: TokenStorageService = inject(TokenStorageService);
  protected backendService: BackendService = inject(BackendService);
  protected route: ActivatedRoute = inject(ActivatedRoute);
  protected translateService: TranslateService = inject(TranslateService);
  protected logger: LoggerService = inject(LoggerService);
  protected errorHandler: ErrorHandler = inject(ErrorHandler);
  protected usrService: UserService = inject(UserService);
  protected router: Router = inject(Router);
  protected toastService = inject(ToastService);
  protected modalService = inject(BaseModalService);
  protected env: GeneralConfig = this.envService.getEnvConfig();
  protected tokenKey: string = this.env.loginType === "local" ? this.env.localTokenKey : this.env.ssoTokenKey;

  async ngOnInit(): Promise<void> { }

  async ngOnDestroy(): Promise<void> { }

}

export class BaseComponentHeader extends BaseComponent {
  constructor() {
    super();
  }

}
export class BaseComponentFooter extends BaseComponent {
  constructor() {
    super();
  }

}
