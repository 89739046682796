<div class="content">
    <div class="size-1 homepage__actions">
        <div class="homepage__actions-action">
            <c-button
                btnItem="{{ 'homepage.UPLOAD_EXCELL' | translate }}"
                btnSize="sm"
                btnStyle="red"
                (onClick)="upload.click()">
            </c-button>
            <input #upload type="file" id="actual-btn" (change)="uploadExcell($event)" hidden/>
        </div>
        <div class="homepage__actions-action">
            <c-button
                btnItem="{{ 'homepage.DOWNLOAD_EXCELL' | translate }}"
                btnSize="sm"
                btnStyle="red"
                (onClick)="downloadVisibilityConeList()">
            </c-button>
        </div>
    </div>

    <div class="size-1 homepage__content">
        <div class="homepage__content-table">
            <c-table 
                [items]="visibilityConeList"
                [stripes]="true"
                [headTmp]="TableHead"
                [bodyTmp]="TableBody">
                <ng-template #TableHead>
                    <c-table-head width="auto">{{ "homepage.table.NAME" | translate }}</c-table-head>
                    <c-table-head width="auto">{{ "homepage.table.HRBP" | translate }}</c-table-head>
                    <c-table-head width="auto">{{ "homepage.table.TOP_HRBP" | translate }}</c-table-head>
                    <c-table-head width="40%">{{ "homepage.table.USERS_HRBP" | translate }}</c-table-head>
                </ng-template>
                <ng-template #TableBody let-item>
                    <c-table-data>{{ item?.name }}</c-table-data>
                    <c-table-data>{{ item?.hrbp.forename }} {{ item?.hrbp.surname }}</c-table-data>
                    <c-table-data>{{ item?.topHrbp.forename }} {{ item?.topHrbp.surname }}</c-table-data>
                    <c-table-data>{{ item?.users | usersSpreader }}</c-table-data>
                </ng-template>
            </c-table>


        </div>

        <div class="homepage__content-pagination">
            <div class="pagination__counter">
                <div class="pagination__counter-info">
                  <p>{{ 'homepage.PER_PAGE' | translate }}</p>
                </div>
                <select name="pagination__counter-counter"
                   (change)="onSizeChange($event)">
                  <option *ngFor="let size of pageSizes" [value]="size">{{ size }}</option>
                </select>
              </div>

            <c-pagination [collectionSize]="visibilityConeCount" [(page)]="currentPage" (pageChange)="onPageChange()"></c-pagination>
        </div>

    </div>
</div>

<div class="loader" *ngIf="uploadLoader">
    <c-loader></c-loader>
</div>

<c-modal
    id="importReport"
    confirmButton="Chiudi"
    [confirmDisabled]="false"
    title="Report caricamenti"
    (onConfirm)="onReportModalClose()"
    (onClose)="onReportModalClose()">
    <div class="wrapper">
        <c-table 
            [stripes]="true"
            [headTmp]="ModalTableHead"
            [bodyTmp]="ModalTableBody">
            <ng-template #ModalTableHead>
                <c-table-head align="center" width="auto">{{ "homepage.modal_table.CONE_CREATED" | translate }}</c-table-head>
                <c-table-head align="center" width="auto">{{ "homepage.modal_table.CONE_UPDATED" | translate }}</c-table-head>
                <c-table-head align="center" width="auto">{{ "homepage.modal_table.CONE_DELETED" | translate }}</c-table-head>
                <c-table-head align="center" width="auto">{{ "homepage.modal_table.CONE_USER_CREATED" | translate }}</c-table-head>
                <c-table-head align="center" width="auto">{{ "homepage.modal_table.CONE_USER_DELETED" | translate }}</c-table-head>
            </ng-template>
            <ng-template #ModalTableBody>
                <c-table-data align="center">{{ fileImportRes?.createdVisibilityConesCount }}</c-table-data>
                <c-table-data align="center">{{ fileImportRes?.updatedVisibilityConesCount }}</c-table-data>
                <c-table-data align="center">{{ fileImportRes?.deletedVisibilityConesCount }}</c-table-data>
                <c-table-data align="center">{{ fileImportRes?.createdVisibilityConeUsersCount }}</c-table-data>
                <c-table-data align="center">{{ fileImportRes?.deletedVisibilityConeUsersCount }}</c-table-data>
            </ng-template>
        </c-table>
    </div>
</c-modal>

