<button [ngStyle]="{ 'padding': padding }" [disabled]="disabled" [tabIndex]="tabIndex" class="g-btn" [ngClass]="{
    'lg': btnSize === 'lg',
    'md': btnSize === 'md',
    'sm': btnSize === 'sm',
    'auto': btnSize === 'auto',
    'red': btnStyle === 'red',
    'ghost': btnStyle === 'ghost',
    'green': btnStyle === 'green',
    'pressed': pressed 
  }" (click)="handleClick(); pressed=true" ariaLabel="{{ 'aria.BUTTON' | translate }} {{ btnLabel }}" translate>
    <p *ngIf="!loading; else loader">{{ btnLabel }}</p>
  
    <ng-template #loader>
      <div class="loader" *ngIf="loading"></div>
    </ng-template>
  </button>