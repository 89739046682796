import { Component } from "@angular/core";
import { TableOptionsDirective } from "../../directives/table-options.directive";

@Component({
  selector: "c-table-head",
  template: "<ng-content></ng-content>",
  styles: [`:host {
    display: table-cell;
    vertical-align: inherit;
    font-size: 12px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 6px;
    padding-left: 12px;
    padding-right: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap
  }`],
  hostDirectives: [
    {
      directive: TableOptionsDirective,
      inputs: ["width", "align"]
    }
  ]
})
export class TableHeadComponent { }
