import { ErrorHandler, inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { EnvironmentLoaderService } from "src/ancestors/env-config.service";
import { LoggerService } from "src/ancestors/logger.service";
import { TokenStorageService } from "./token-storage.service";
import { BackendService } from "./backend-api.service";
import { TranslateService } from "../shared/util/translate.service";
import { AppLoginInfo } from "generali-visibilities-tool-cl";

@Injectable()
export class TokenManagerService {
  constructor() {
    this.logger.setCaller("TokenManagerService");
  }
  private envConfig: EnvironmentLoaderService = inject(EnvironmentLoaderService);
  private tStorage: TokenStorageService = inject(TokenStorageService);
  private authApi: BackendService = inject(BackendService);
  private errorHandler = inject(ErrorHandler);
  private logger: LoggerService = inject(LoggerService);
  private router: Router = inject(Router);
  private translate: TranslateService = inject(TranslateService);

  private curRetryAttempts = 0;
  private maxTryAttempts = 3;

  public getTokenProfiles(): string[] {
    const loginRes: AppLoginInfo = this.tStorage.retriveToken(this.envConfig.getEnvConfig().localTokenKey);

    return loginRes.jwtPayload.auths;
  }

  /**
   * 
   * Schedulazione del rinnovo del token
   * 
   * @param token
   */
  public refreshTokenScheduler(token: AppLoginInfo): void {
    let newToken;

    if (JSON.stringify(token) !== "{}") {
      /** Calcola quanti secondi mancano al rinnovo (NON SCADENZA) del token */
      const msBeforeRefresh: number = this.msBeforeRefresh(new Date(), new Date(token.expireDate));


      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      setTimeout(async (): Promise<void> => {
        try {
          /** Aggiorna il token */
          newToken = await this.refreshToken(token);

          if (!newToken) {
            throw new Error(this.translate.instant("error.NO_TOKEN"));
          }

          /** Cancella il vecchio */
          this.tStorage.deleteToken(this.envConfig.getEnvConfig().localTokenKey);

          /** Salva il nuovo */
          this.tStorage.saveToken(this.envConfig.getEnvConfig().localTokenKey, newToken);

          /** Schedula il nuovo refresh */
          this.refreshTokenScheduler(newToken);
        } catch (err: unknown) {
          this.errorHandler.handleError(err);
          /** In caso di errore fai al massimo 3 tentativi di refresh prima di visualizzare l'errore e reindirizzare l'utente */
          if (this.curRetryAttempts <= this.maxTryAttempts) {
            this.curRetryAttempts++;
            this.refreshTokenScheduler(token);
          } else {
            this.tStorage.deleteToken(this.envConfig.getEnvConfig().localTokenKey);
            this.curRetryAttempts = 0;
            await this.router.navigate([this.envConfig.getEnvConfig().loginUrl]);
          }
        }
      }, msBeforeRefresh);
    }
  }

  /**
   * Confronta la differenza tra data attuale - data di scadenza - tempo di sicurezza
   *
   * @param dateFuture
   * @returns Secondi prima della scadenza del token
   */
  private msBeforeRefresh(dateA: Date, dateFuture: Date): number {
    const safetyTime: number = 1000 * 60 * this.envConfig.getEnvConfig().checkTokenValidityBeforeExpireMin;
    const safetyDate: number = new Date(dateFuture).getTime() - safetyTime;
    const msLeft: number = Math.floor(safetyDate - dateA.getTime());

    return +msLeft.toFixed(0);
  }
  /**
   * 
   * - True: se il token è scaduto
   * - False: se il token non è scaduto
   *
   * @param token
   * @returns
   */
  public checkTokenValidity(token: AppLoginInfo): boolean {
    const actualDate = new Date();
    const expireDate: Date = new Date(token.expireDate);
    if (token && expireDate) {
      if (actualDate.getTime() < expireDate.getTime()) {
        return true;
      }
    }

    return false;
  }
  /**
   *
   * @async Token aggiornato
   *
   * @param token Oggetto token attualmente in uso, salvato nello storage ANCORA VALIDO
   */
  public async refreshToken(token: AppLoginInfo): Promise<AppLoginInfo | undefined> {
    const authData = await this.authApi.refreshToken();

    if (!authData) {
      return;
    }
    /** Copia del token che ricevo per salvare le informazioni dell'utente */
    const newToken: AppLoginInfo = { ...token };

    /** Sovrascrivi token vecchio con nuovo e data di scadenza */
    newToken.token = authData.token;
    newToken.expireDate = authData.expireDate;

    /** Ritorna nuovo token oggetto da salvare nello storage */
    return newToken;

  }
}

