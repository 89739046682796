import { ErrorHandler, inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { API_REQUEST_METHODS, HttpService } from "src/ancestors/base-api.service";
import { TokenStorageService } from "src/app/global-service/token-storage.service";
import { AppLoginInfo, VisibilityCone, VisibilityConeImportResult } from "generali-visibilities-tool-cl";

export interface UserParams {
  email: string;
  password: string;
  langCode?: string;
  deviceType?: string;
  userAgent?: string;
  createPersistentUserAuth?: string;
}

@Injectable()
export class BackendService extends HttpService {
  constructor() {
    super();
    this.logger.setCaller("BackendService");
    this.createInstance({ url: this.env.apiBaseUrl });
  }

  private tkStorage: TokenStorageService = inject(TokenStorageService);
  private router: Router = inject(Router);
  private errorHandler = inject(ErrorHandler);

  /** AUTH */

  /**
   * @param loginParam parametri per eseguire il login.
   */
  async localLogin(loginParam: UserParams): Promise<AppLoginInfo | undefined> {
    try {
      const loginRes = await this.httpClient<AppLoginInfo>("authn/login", {
        method: API_REQUEST_METHODS.POST,
        body: loginParam
      });


      if (loginRes) {
        return loginRes;
      }


    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  /**
   * Rinnova il token di autenticazione ed invalida il precedente.
   */
  async refreshToken(): Promise<AppLoginInfo | undefined> {
    try {
      const refreshToken = await this.httpClient<AppLoginInfo>("authn/refreshToken", {
        method: API_REQUEST_METHODS.POST
      });

      if (refreshToken) {
        return refreshToken;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  async decodeSingleUseToken(singleUseToken: string): Promise<AppLoginInfo | undefined> {
    try {
      const decodeSingleUseToken = await this.httpClient<AppLoginInfo>("authn/decodeSingleUseToken", {
        query: { singleUseToken }
      });

      if (decodeSingleUseToken) {
        return decodeSingleUseToken;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  async createSingleUseToken(): Promise<string | undefined> {
    try {
      const createSingleUseToken = await this.httpClient<AppLoginInfo>("/authn/createSingleUseToken", {
        method: "post"
      });

      if (createSingleUseToken) {
        return createSingleUseToken.token;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return;
  }

  /**
   * @returns Invalida il token dell'utente e ne impedisce l'uso e il rinnovo.
   */
  public async logout(): Promise<boolean> {
    try {
      const logoutRes = await this.httpClient<AppLoginInfo>("authn/logout", {
        method: API_REQUEST_METHODS.POST
      });

      if (logoutRes) {
        return true;
      }
    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return false;
  }

  /**
   * Recupera i coni di visibiltà definiti in piattaforma
   * 
   * @param fromRecord 
   * @param numRecords
   * @returns VisibilityCone[]
   */
  async visibilityConeList(fromRecord: number, numRecords: number): Promise<VisibilityCone[]> {
    try {
      const visibilityConeList = await this.httpClient<VisibilityCone[]>("/visibilityCone/list", {
        query: { fromRecord, numRecords }
      });

      if (visibilityConeList) {
        return visibilityConeList;
      }

    } catch (error: unknown) {
      this.errorHandler.handleError(error);
    }

    return [];
  }

  /**
   * Conta i coni di visibiltà definiti in piattaforma
   * 
   * @param fromRecord 
   * @param numRecords
   * @returns Number
   */
    async visibilityConeCount(): Promise<number> {
      try {
        const visibilityConeCount = await this.httpClient<number>("/visibilityCone/count");
  
        if (visibilityConeCount) {
          return visibilityConeCount;
        }
  
      } catch (error: unknown) {
        this.errorHandler.handleError(error);
      }
  
      return 0;
    }

    /**
     * Import dei coni di visibilità seguendo il tracciato excel definito
     * 
     * @param file 
     * @param dryRun Flag che specifica se l'importazione deve effetutare le modifiche a db o se deve semplicemente simulare l'importazione
     * @returns VisibilityConeImportResult
     */
    async visibilityConeImport(file: FormData, dryRun: boolean): Promise<VisibilityConeImportResult | undefined> {
      try {
        const importResult = await this.httpClient<VisibilityConeImportResult>("/visibilityCone/import", {
          method: "post",
          query: { dryRun },
          body: file,
          formData: true,
          preventFastFlickering: true
        });
        
        if (importResult) {
          return importResult;
        }
  
      } catch (error: unknown) {
        this.errorHandler.handleError(error);
      }
  
      return undefined;
    }

    /**
     * Esporta in un file xlsx i dati dei coni di visibilità attualmente definiti a db, seguendo lo stesso tracciato che viene utilizzato anche per il loro import
     * @returns Nome file
     */
    async currentVisibilityConesReportName(): Promise<string | undefined> {
      try {
        const fileName = await this.httpClient<string>("export/currentVisibilityCones");
  
        if (fileName) {
          return fileName;
        }
  
      } catch (error: unknown) {
        this.errorHandler.handleError(error);
      }
  
      return undefined;
    }

    /**
     * Scarica e CANCELLA il file dalla directory temporanea, validando preventivamente il token monouso passato
     * 
     * @param fileName 
     * @param authToken 
     */
    public fileDownload(fileName: string, authToken: string) {
      const path = this.createPath(this.env.apiBaseUrl, "/export/downloadFile", { fileName, authToken });
      window.open(path, "_self");
    }
}