import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";

import { EnvironmentLoaderService } from "src/ancestors/env-config.service";
import { UrlCollection } from "../app-routing.module";
import { BackendService } from "./backend-api.service";
import { TokenManagerService } from "./token-manager.service";
import { TokenStorageService } from "./token-storage.service";
import { AppLoginInfo } from "generali-visibilities-tool-cl";

/**
 * Ottiene l'URL di reindirizzamento basato su una URL fornita e un percorso di base.
 *
 * @param {string} url - L'URL di origine, con eventuali trasformazioni come la sostituzione di "/#/" con "/".
 * @param {string} baseHref - Il percorso di base da rimuovere dall'URL di reindirizzamento.
 * @returns {string | null} L'URL di reindirizzamento ottenuto, o null se non presente.
 */
const getRedirectUrlFn = (url: string, baseHref: string): string | null => {
  const actualUrl = new URL(url.replace("/#/", "/"));
  const originalRequestedUrl = actualUrl.searchParams.get("originalRequestedUrl");
  let redirectUrl: string | null = originalRequestedUrl ? originalRequestedUrl : (actualUrl.pathname === "/" ? null : actualUrl.pathname);

  if (baseHref && redirectUrl?.startsWith(baseHref)) {
    redirectUrl = redirectUrl.substring(baseHref.length);
  }

  return redirectUrl;
};

/**
 * Fa il redirect all'url di login, in base al tipo di login
 * aggiungendo anche l' originalRequestedUrl per tornare all'url originale dopo il login
 *
 * @param {string} urlSnapshot - alla qualle si tenta di accedere.
 */
const redirectToLoginUrlFn = async (urlSnapshot: string): Promise<void> => {
  const envConfig = inject(EnvironmentLoaderService);
  const env = envConfig.getEnvConfig();
  const router = inject(Router);

  switch (env.loginType) {
    case "local": {
      await router.navigate([env.loginUrl], {
        queryParams: { originalRequestedUrl: urlSnapshot }
      });
      break;
    }
    case "saml-sso": {
      const actualUrl = new URL(window.location.href.replace("/#/", "/"));
      const redirectUrl = new URL(actualUrl);
      const baseHref = env.baseHref;

      /** aggiunge l'url originale in cui tornare dopo il login */
      let originalRequestedUrl = redirectUrl.pathname === "/" ? undefined : redirectUrl.pathname;

      if (baseHref && originalRequestedUrl?.startsWith(baseHref)) {
        originalRequestedUrl = originalRequestedUrl.substring(baseHref.length);
      }

      await router.navigate([UrlCollection.samlSso], { queryParams: { originalRequestedUrl } });
      break;
    }
    default: {
      await router.navigate([env.loginUrl], {
        queryParams: { originalRequestedUrl: urlSnapshot }
      });
    }
  }
};

/**
 * Implementa una guard di navigazione per verificare l'autenticazione utente.
 *
 * @param {ActivatedRouteSnapshot} route - Lo stato dell'attuale route attivato.
 * @param {RouterStateSnapshot} state - Lo stato corrente del router.
 * @returns {Promise<boolean>} Una Promise che restituisce true se l'utente è autenticato, altrimenti false.
 */
export const AuthenticationGuardFn: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  const router = inject(Router);
  const envConfig = inject(EnvironmentLoaderService);
  const tkManager = inject(TokenManagerService);
  const tStorage = inject(TokenStorageService);
  const backendService = inject(BackendService);
  const env = envConfig.getEnvConfig();


  const tokenKey = env.loginType === "local" ? env.localTokenKey : env.ssoTokenKey;
  const actualUrl = new URL(window.location.href.replace("/#/", "/"));
  const tokenExist = JSON.stringify(tStorage.retriveToken(tokenKey)) === "{}" ? false : true;
  const ssortkqp = actualUrl.searchParams.get("ssortkqp");

  if (!tokenExist && env.loginType === "saml-sso" && ssortkqp) {
    const loginInfo = await backendService.decodeSingleUseToken(ssortkqp);
    if (!loginInfo) {
      return router.navigate([UrlCollection.samlSso]);
    }

    tStorage.saveToken(tokenKey, loginInfo);
    const redirectUrl = getRedirectUrlFn(window.location.href, env.baseHref);

    return router.navigate([redirectUrl ?? "homepage"]);
  }

  const token: AppLoginInfo = tStorage.retriveToken(tokenKey);
  const tokenIsValid: boolean = tkManager.checkTokenValidity(token);

  switch (env.storeAccessToken) {
    case "cookies": {

      if (token && tokenIsValid) {
        return true;
      }
      return false;
    }
      
  
    default: {
      /**
       * Se esiste un token di autenticazione valido verrà permesso di eseguire l'accesso
       */
      if (token && tokenIsValid) {
        return true;
      }
    
      /** Il token scaduto verrà eliminato */
      tStorage.deleteToken(tokenKey);
      
      /** Il token scaduto verrà eliminato */
      tStorage.deleteToken(tokenKey);
    
      await redirectToLoginUrlFn(state.url);
    
      return false;
    }
  }
};