<div class="header__wrapper">
  <!-- Burger + logo Generali -->
  <div class="header__logo-wrapper">
    <div class="header__logo-img" (click)="onLogoClicked()">
      <img src="./assets/icons/logo-generali.svg">
    </div>
  </div>

  <!-- Nome applicazione -->
  <div class="header__name d-none d-sm-block" [innerHTML]="'banner.NAME' | translate"></div>
  <div class="header__info ms-auto" (click)="onToggleMenu()">
    <img src="./assets/icons/user-icon.svg">
  </div>
</div>

<div class="header-container">

</div>

<c-drawer
  [(show)]="showMenu"
  [items]="items"
  (onClick)="onRedirection.emit($event)">
</c-drawer>