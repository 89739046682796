<div class="drawer-container">
  <div class="drawer__container" [class.show]="show">
    <div class="drawer__container-header">
      <div (click)="showChange.emit(false)">
        <img src="./assets/icons/close-red.svg">
      </div>
      
      <div>
        <p>{{ title ?? ("generic.MENU" | translate) }}</p>
      </div>
    </div>
    <ul
      class="drawer__container-options">
        <ng-container *ngFor="let item of items">
          <li
            (click)="onClick.emit(item);
            showChange.emit(false)" 
            *ngIf="item.canDisplay || item.canDisplay == undefined">
            {{ item.name }}
          </li>
        </ng-container>
    </ul>
  </div>
  <div
    class="drawer__overlay"
    *ngIf="show"
    (click)="showChange.emit(false)" >
  </div>
</div>