import { Component, EventEmitter, Input, Output } from "@angular/core";


@Component({
  selector: "c-button",
  templateUrl: "./c-button.component.html",
  styleUrls: ["./c-button.component.scss"]
})
export class ButtonComponent {
  constructor() { }

  /** 
   * True dopo il click del bottone
   * Aggiunge la classe pressed 
   */
  protected pressed = false;

  /** 
   * @type { ButtonType }
   * Elemento passato al bottone
   */
  @Input() btnItem?: string;
  /** Nel caso in cui venga passato un oggetto la label prenderà il valore assegnato a questo parametro */
  @Input() btnValue?: string;
  /** */
  @Input() loading?: boolean;
  /** 
   * @type { ButtonSize }
   * Setta la grandezza 
   */
  @Input() btnSize?: "lg" | "md" | "sm" | "auto";
  /**
   * @type { ButtonStyle }
   * Setta lo stile
   */
  @Input() btnStyle?: "red" | "ghost" | "green";
  /** Accessibilità */
  @Input() tabIndex = 0;
  /** Stato  */
  @Input() disabled? = false;
  @Input() padding?: string;
  /** Evento di click emesso */
  @Output() onClick = new EventEmitter();

  /** Gestione evento di click */
  handleClick() {
    if (!this.loading) {
      this.onClick.emit();
    }
  }

  /** In base al parametro di input setto il valore della label */
  get btnLabel() {
    return this.btnItem;
  }

}
