import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseComponent } from "src/ancestors/base/base.component";

export interface DrawerItems {
  id?: string;
  name: string;
  redirectTo?: string;
  canDisplay?: boolean;
}

@Component({
  selector: "c-header",
  templateUrl: "./c-header.component.html",
  styleUrls: ["./c-header.component.scss"]
})
export class HeaderComponent extends BaseComponent {
  @Input() items?: DrawerItems[] = [];
  @Input() showMenu?: boolean;
  @Output() onRedirection = new EventEmitter<DrawerItems>();
  @Output() onOpen = new EventEmitter<void>();

  protected async onLogoClicked() {
    // await this.router.navigate([UrlCollection.user]);
  }

  protected onToggleMenu() {
    this.showMenu = !this.showMenu;
    this.onOpen.emit();
  }
}
