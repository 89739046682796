import { Component, Input, inject } from "@angular/core";
import { ToastService } from "./c-toast.service";

@Component({
  selector: "c-toast",
  templateUrl: "./c-toast.component.html",
  styleUrls: ["./c-toast.component.scss"]
})
export class ToastComponent {
  protected toastService = inject(ToastService);
  @Input() hideAfter?: number;

  ngOnDestroy(): void {
    this.toastService.clear();
  }
}
