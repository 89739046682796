<div class="pagination-wrapper">

  <!-- DESIGN NORMALE -->
  <div class="pagination__box" *ngIf="design === 'normal'">

    <!-- Prima pagina -->
    <!-- <div class="pagination__box-prev page-box" (click)="goToFirstPage()">
      <img src="./assets/icons/arrow-left.svg">
    </div> -->

    <!-- NEXT -->
    <div class="pagination__box-prev page-box" [class.disabled]="page == 1" (click)="goToPrevPage()">
      <img src="./assets/icons/chevron-left.svg">
    </div>

    <div class="pagination__box-page page-box" *ngFor="let _page of _pages"
      [ngClass]="{ 'active-page': page == _page, 'me-1': _page >= 100 }" (click)="setCurrentPage(_page)">
      {{ _page }}
    </div>

    <!-- <div class="pagination__box-page page-box" *ngIf="page + pageSize - 1 < _collectionSize">
      ...
    </div> -->

    <!-- Ultima pagina -->
    <div class="pagination__box-page page-box" *ngIf="page + pageSize - 1 < _collectionSize"
      [ngClass]="{ 'active-page': _collectionSize == page }" (click)="goToLastPage()">
      {{ _collectionSize }}
    </div>

    <!-- PREV -->
    <div class="pagination__box-next page-box" [class.disabled]="page == _collectionSize" (click)="goToNextPage()">
      <img src="./assets/icons/chevron-right.svg">
    </div>

    <!-- Ultima pagina -->
    <!-- <div class="pagination__box-next page-box" (click)="goToLastPage()">
      <img src="./assets/icons/arrow-right.svg">
    </div> -->
  </div>


  <!-- DESIGN INPUT -->
  <div class="pagination__box pagination__box-input" *ngIf="design === 'input'">
    <div class="pagination__box-prev page-box" (click)="goToPrevPage()">
      <img src="./assets/icons/chevron-left.svg">
    </div>
    <c-input type="number" placeholder="" textAlign="center" [min]="1" [max]="_collectionSize" [autoHeight]="true"
      [(ngModel)]="page" (onChange)="setCurrentPage($event, 900)">
    </c-input>
    <div class="pagination__box-next page-box no-border">/ {{ _collectionSize }}</div>
    <div class="pagination__box-next page-box" (click)="goToNextPage()">
      <img src="./assets/icons/chevron-right.svg">
    </div>
  </div>
</div>