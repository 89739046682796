import { Injectable, inject } from "@angular/core";
import { UrlCollection } from "../app-routing.module";
import { Router } from "@angular/router";

@Injectable()
export class ApplicationDataService {
  private router: Router = inject(Router);
  private _showHeader = false;

  public stringify(param?: unknown): string  | undefined {
    if (!param) return undefined;

    return typeof param === "string" ? param : JSON.stringify(param);
  }

  /**
   * Scorre la pagina verso il punto specificato.
   * 
   * @param ancorTagId L'ID dell'ancora a cui si desidera scorrere la pagina.
   */
  public scrollTo(ancorTagId: string) {
    const ancor = document.querySelector(ancorTagId);
    const position = ancor?.getBoundingClientRect();
    if (position) {
      window.scrollTo(0, document.body.scrollHeight - position.height);
    }
  }

  public isEmpty(obj: unknown): obj is undefined {
    if (typeof obj === "string") {
      return obj === "" ? true : false;
    }

    if (obj === undefined || obj === null) {
      return true;
    }

    if (JSON.stringify(obj) === "{}") {
      return true;
    }

    if (JSON.stringify(obj) === "[]") {
      return true;
    }

    return false;
  }

  public setHeaderVisibility() {
    if (this.router.url.includes(UrlCollection.localAuthentication)) {
      this._showHeader = false;
    } else {
      this._showHeader = true;
    }
  }

  public get showHeader(): boolean {
    return this._showHeader;
  }
}
